.header {
  padding-top: 40px;
  padding-bottom: 20px;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 99;
  transition: all 0.4s;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;

  &__logo {
    color: $color-dark;
    font-weight: 300;
    line-height: 0.9;
    font-size: 25px;
    transition: all 0.4s;
    transform-origin: center left;
    height: 60px;

    & a {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &-text {
      margin-left: 10px;
      font-size: 20px;
      line-height: 1;
      color: $color-dark;
      font-weight: 300;

      @include media-breakpoint-down(md) {
        font-size: 18px;
      }
    }
    & img {
      flex-shrink: 0;
      height: 100%;
    }

    &--small {
      font-size: 18px;
      color: #fff;
    }

    @include media-breakpoint-down(md) {
      height: 45px;
    }
  }
  &.active {
    padding: 10px 0;
    transition: all 0.4s;
    background: #fff;
    height: 80px;
    box-shadow: 10px 4px 50px rgba(229, 233, 246, 0.4);
    & .header__logo {
      transform: scale(0.8);
    }

    & .logo a {
      height: 100%;
    }

    @include media-breakpoint-down(md) {
      height: 60px;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 20px;
    height: 90px;
  }
}

.subheader {
  padding: 123px 0 80px 0;
  position: relative;
  margin-top: 70px;

  &:before {
    content: '';
    position: absolute;
    width: 151px;
    height: 115px;
    background-image: url('./img/elem1.svg');
    left: 0;
    top: 100px;
    transform: translateX(-20%);
    z-index: -1;
  }
  &__title {
    color: $color-dark;
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 45px;
    line-height: 1.1;

    @media all and (max-width: 1368px) {
      font-size: 35px;
      line-height: 1.2;
    }
  }
  &__text {
    font-size: 21px;
    line-height: 32px;
    margin-bottom: 45px;

    @media all and (max-width: 1368px) {
      font-size: 16px;
      line-height: 1.4;
    }
  }

  &__container {
    position: absolute;
    right: 0;
    top: 70px;
    z-index: -1;

    @include media-breakpoint-down(md) {
      position: static;
      top: 0;
      z-index: 4;
    }
  }
  &__img {
    & img {
      margin-left: auto;
      display: block;
    }
  }
  @include media-breakpoint-down(lg) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
}

.modal {
  & .modal-title {
    color: $color-black;
  }
  padding-right: 0 !important;
  & .modal-content {
    border-radius: 0;
    border: none;
    height: 70vh;
  }
  & .modal-dialog {
    & iframe {
      width: 100%;
      height: 100%;
      // min-height: 450px;
    }
  }
}
.modal-open {
  padding-right: 0 !important;
}

.nav {
  margin-left: auto;
  width: 100%;
  position: relative;
  height: 100%;
  & > div {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  &__container {
    height: 100%;

    @include media-breakpoint-down(lg) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#fff, 0.95);
      z-index: 999;
      transform: translateX(100%);
      transition: 0.4s transform ease-in;
    }
  }
  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      justify-content: center;
      padding-top: 80px;
    }
  }

  &__item {
    padding: 2px 15px;
    font-size: 17px;
    &:last-of-type {
      border: 2px solid $color-primary;
      border-radius: 20px;
      font-weight: 700;
    }
    &.active {
      & .nav__link {
        font-weight: 700;
      }
    }
    @media all and (max-width: 1368px) {
      font-size: 16px;
    }
    @include media-breakpoint-down(xl) {
      font-size: 15px;
      padding: 2px 10px;
    }

    @include media-breakpoint-down(lg) {
      font-size: 18px;
      padding: 2px 20px;
      margin: 15px 0;
    }
  }
  &__link {
    text-decoration: none;
    color: #1f1534;

    &:hover {
      color: #1f1534;
    }
  }

  & .hamburger {
    width: 30px;
    height: 30px;
    padding: 5px 0;
    display: none;
    background: none;
    border: none;
    position: relative;
    z-index: 999999;
    top: 0px;
    & div {
      width: 30px;
      height: 2px;
      position: relative;
      background-color: $color-primary;
      transition: 0.2s;
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
    }
  }

  &.active {
    & .hamburger {
      position: fixed;
      right: 25px;
      top: 30px;
    }
    & .hamburgerLine1 {
      transform: rotate(45deg);
      position: absolute;
      z-index: 10;
    }

    & .hamburgerLine2 {
      display: none;
      transform: rotate(90deg);
      z-index: 1;
    }
    & .hamburgerLine3 {
      transform: rotate(-45deg);
      position: absolute;
      z-index: 10;
    }
    & .nav {
      transform: translateX(0);
    }
  }
  &.active {
    & .nav__container {
      transform: translateX(0%);
    }
  }
}

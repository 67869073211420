.main-btn {
  // background-color: $color-primary;
  background: linear-gradient(183.41deg, #67c3f3 -8.57%, #5a98f2 82.96%);
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  padding: 13px 25px;
  display: inline-block;
  transition: 0.4s transform ease-in-out;

  &--small {
    border-radius: 20px;
    padding: 2px 15px;
    font-size: 14px;
    border: none;
  }

  &:hover {
    color: #fff;
  }

  &:hover {
    transform: scale(1.05);
  }
}

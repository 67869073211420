.text-blue {
  color: $color-primary;
}
.text-white {
  color: #fff;
}
.text-dark {
  color: $color-black;
}
.section {
  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 56px;
    color: $color-dark;
    padding-bottom: 26px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &::after {
      content: '';
      width: 56px;
      height: 2px;
      background: $color-dark;
      display: block;
    }
    @media all and (max-width: 1368px) {
      font-size: 28px;
      line-height: 1.4;
      margin-bottom: 0;
    }
  }
}
.box {
  &-col {
    margin-bottom: 30px;
  }
  box-shadow: 10px 4px 50px rgba(229, 233, 246, 0.4);
  border-radius: 20px;
  background: #fff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //   justify-content: center;
  height: 100%;

  &__content {
    padding: 20px;
    width: 100%;
  }
  &__img {
    width: 100%;

    & img {
      height: 250px;
      width: 100%;
      object-fit: cover;
      object-position: 50% 40%;
    }
    &-label {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      background: #eb2929e0;
      color: #fff;
      font-size: 16px;
      text-align: center;
      font-weight: 700;
      padding: 5px;

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
  }
  &__info {
    display: flex;
    font-size: 14px;
    line-height: 1.3;
    align-items: center;
    text-align: left;
    align-self: flex-start;
    margin-bottom: 10px;
    width: 100%;
    & img {
      flex-shrink: 0;
      width: 18px;
      height: 18px;
      margin-right: 20px;
    }
  }
  & hr {
    width: 100%;
  }
  &__icon {
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
    // background-color: $color-primary;
    background: linear-gradient(183.41deg, #67c3f3 -8.57%, #5a98f2 82.96%);
    // background: $color-secondary;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 5px rgba(129, 129, 129, 40%);

    & img {
      flex-shrink: 0;
      width: 50px;
    }
  }
  &__number {
    font-size: 18px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    // border: 2px solid $color-secondary;
    background: linear-gradient(183.41deg, #67c3f3 -8.57%, #5a98f2 82.96%);
    color: $color-text;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 700;
  }
  &__title {
    color: $color-dark;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 700;
    text-align: center;

    @media all and (max-width: 1368px) {
      font-size: 18px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
  }

  @include media-breakpoint-down(lg) {
    padding: 20px;
  }
}
.cookiealert {
  position: fixed;
  background: #f9f9f9;
  border-radius: 0;
  font-size: 16px;
}
.label-nfz {
  display: flex;
  align-items: center;
  width: 100%;

  &__circle {
    font-size: 14px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;
    border: 1px solid #eb2929e0;
    color: #eb2929e0;
    background: #eb2929e0;
    color: #fff;
    width: auto;
    font-weight: 700;
    box-shadow: 1px 1px 10px #8181812e;
    text-align: center;
    margin-left: auto;
    border-radius: 5px;
    padding: 5px 10px;
  }
}
.label-nfz--white {
  border: 1px solid white;
  color: white;
}

// @import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap';

@import 'base/typography';

@import 'helpers/variables';

@import 'helpers/mixins';

@import 'base/reset';

@import 'layout/header';

@import 'layout/subheader';

@import 'layout/content';

@import 'layout/footer';

@import 'layout/general';

@import 'components/nav';

@import 'components/buttons';

@import 'components/modal';

// pages

@import 'pages/home-page';

.footer {
  padding: 50px 0;
  background: linear-gradient(183.41deg, #67c3f3 -8.57%, #5a98f2 82.96%);
  & .header__logo {
    color: #fff;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    color: #fff;

    @include media-breakpoint-down(md) {
      margin-top: 30px;
      font-size: 18px;
    }
  }

  &__contact {
    font-size: 16px;
    color: #fff;
  }
  &__menu {
    font-size: 16px;
    & li {
      margin-bottom: 5px;
    }
  }

  & a {
    color: #fff;
  }
  &__logo {
    height: 60px;

    & a {
      flex-shrink: 0;
      height: 100%;
      display: flex;
      align-items: center;
    }
    &-text {
      margin-left: 10px;
      font-size: 20px;
      line-height: 1;
      color: #fff;

      @include media-breakpoint-down(md) {
        font-size: 18px;
      }
    }
    & img {
      height: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 30px 0;
  }
}

.offer {
  padding: 100px 0;
  &__title {
    text-align: center;
    &::after {
      margin: 0 auto;
    }
  }
  &__text {
    margin-bottom: 60px;
    &-2 {
      margin-top: 50px;
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: 40px;
    }
  }
  @include media-breakpoint-down(xl) {
    padding-top: 0;
  }
  @include media-breakpoint-down(lg) {
    padding: 50px 0;
  }
}
.benefits {
  position: relative;
  margin-bottom: 180px;

  & > .container {
    position: relative;
    z-index: 1;
  }

  &__container {
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 0;
    &-wrapper {
      position: relative;
      height: 100%;
      width: 100%;
      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-image: url('./img/shape1.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 140% 0;
      }
    }

    @include media-breakpoint-down(md) {
      position: static;
      margin-bottom: 30px;
    }
  }
  &__img {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
      width: 129px;
      height: 129px;
      background-image: url('./img/circle.png');
    }
    & img {
      margin-right: auto;
      display: block;
      border-radius: 0 0 200px 0px;
    }
  }
  &__title {
    margin-bottom: 20px;
    margin-top: 10px;
  }
  &__list {
    padding-left: 7px;
    & li {
      position: relative;
      padding-left: 15px;
      margin-bottom: 7px;
      &:before {
        content: '';
        width: 7px;
        height: 7px;
        display: block;
        background: $color-primary;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: 15px;
        position: absolute;
        top: 12px;
        left: 0;
      }
    }
  }
  @include media-breakpoint-down(xl) {
    margin-bottom: 100px;
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 80px;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 0;
    padding-bottom: 35px;
  }
}
.producers {
  margin-top: 100px;
  padding-bottom: 150px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 151px;
    height: 115px;
    background-image: url('./img/elem1.svg');
    left: 0;
    top: -50px;
    transform: translateX(-20%);
  }
  &__title {
    text-align: center;
    margin-bottom: 30px;

    &:after {
      margin: 0 auto;
    }
  }
  &__logo {
    & img {
      max-height: 80px;
      max-width: 200px;
      object-fit: contain;
      margin: 0 auto;
      display: block;

      &.bigger {
        max-height: 250px;
      }
      @include media-breakpoint-down(lg) {
        max-width: 150px;
      }
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: 30px;
    }
  }

  @include media-breakpoint-down(lg) {
    margin-top: 50px;
    padding-bottom: 50px;
  }
}
.specialists {
  background: #f9f9f9;
  &__top {
    background-image: url(./img/img6.jpg);
    background-size: cover;
    background-position: center left;
    height: 400px;
    width: 100%;
    margin-bottom: 60px;

    @include media-breakpoint-down(lg) {
      height: 300px;
    }
  }
  margin-top: 70px;
  padding-bottom: 50px;

  &__box {
    &-title {
      font-weight: 300;
      margin-bottom: 5px;
    }
  }
  & .main-btn {
    margin-top: 30px;

    @include media-breakpoint-down(xl) {
      margin-bottom: 50px;
    }
  }
  @include media-breakpoint-down(md) {
    margin-top: 50px;
  }
}
.offices {
  & .box {
    padding: 0;

    &__img {
      overflow: hidden;
      position: relative;
      cursor: pointer;
      height: 250px;

      & img {
        transition: 0.4s transform ease-in-out;
      }
      &-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        background: none;
        border: 2;
        padding: 10px 15px;
        border: 2px solid #fff;
        color: #fff;
        opacity: 0;
        transition: 0.4s opacity ease-in-out;
      }

      &-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 1;
        transform: translateY(100%);
        transition: 0.3s transform ease-out;
      }

      &:hover {
        & img {
          transform: scale(1.1);
        }
      }
    }
    &__content {
      height: calc(100% - 250px);
      display: flex;
      flex-direction: column;
    }
    &__btn {
      margin: auto;
      background: none;
      border-radius: 20px;
      border: 2px solid $color-primary;
      padding: 4px 15px;
      font-size: 13px;
      text-transform: uppercase;
      color: $color-dark;
      font-weight: 700;
      width: 50%;
      margin-bottom: 0;
      transition: 0.4s all ease-in-out;

      &:hover {
        background: $color-primary;
        color: #fff;
      }
      @media all and (max-width: 1368px) {
        font-size: 12px;
      }
      @include media-breakpoint-down(lg) {
        width: 100%;
        margin: 0;
        margin-top: auto;
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 35px;
  }
}
.contact {
  //   background: linear-gradient(183.41deg, #67c3f3 -8.57%, #5a98f2 82.96%);
  padding: 50px 0;
  color: #fff;
  background: $color-primary;

  &__title {
    color: #fff;

    &:after {
      background-color: #fff;
    }
  }
}

$base-font-family: 'Mulish', sans-serif;
//$base-font-family: 'Lato', sans-serif;

$color-text: #7d7987;
$color-primary: #458ff6;
$color-dark: #000000;

// Line-Height
$base-line-height: 30px;

body {
  font-size: 18px;
  line-height: $base-line-height;
  font-family: $base-font-family !important;
  color: $color-text;
  font-weight: 300;

  @media all and (max-width: 1368px) {
    font-size: 16px;
    line-height: 1.4;
  }
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style-type: none;
}
p {
  margin-bottom: 0;
}
